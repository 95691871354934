*:before {
    border-bottom-style: solid !important;
}

textarea {
    -webkit-text-fill-color: rgba(0, 0, 0, 1) !important;
    color: black !important;
    opacity: 1 !important;
}

body {
    margin: 0;
}

.postContainer {
    width: 50vw;
    margin-left: 25vw;
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 20px;
    padding: 20px;
    background-color: #f5f5f5;
    border-radius: 8px;
    position: relative;
}

.postContainer:hover .buttonContainer button {
    display: initial;
}

.buttonContainer {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    position: absolute;
    top: 15px;
    right: 15px;
}

.buttonContainer button {
    display: none;
}

.textContainer {
    width: 100%;
    margin-bottom: 10px;
}

.saveButton {
    display: none;
    
}